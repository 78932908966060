import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import {
    ContainerHeight,
    ContainerBanner,
    TituloPrincipal,
    TituloSecundario,
} from '../styled-component/404';

const Container404 = () => (
    <ContainerBanner>
        <ContainerHeight>
            <StaticImage
                src="../images/404/logo-404.svg"
                className="logo__Mateminds"
                alt="Logo Mateminds"
                placeholder="none"
                layout="fixed"
                loading="eager"
            />
            <StaticImage
                src="../images/404/404.svg"
                className="logo__404"
                alt="Logo 404"
                placeholder="none"
                layout="fixed"
                loading="eager"
            />
            <TituloPrincipal>
                Oops! Nada por aquí
            </TituloPrincipal>
            <TituloSecundario>
                La página que estás buscando no ha sido encontrada
            </TituloSecundario>
            <Link to="/">
                Volver
            </Link>
        </ContainerHeight>
    </ContainerBanner>
);

export default Container404;
